/* eslint-disable */
import Supplier from './Supplier'
import _ from 'lodash'

export default class SuppliersPrice {

  /**
   * Constructor
   * @param suppliers
   * @param parts
   * @param orders
   */
  constructor (suppliers, parts, orders = [], partsOrdered = [], rfqStatus = '') {
    console.log('SUPPLIERS', suppliers, Array.isArray(suppliers), (typeof suppliers === 'object' && !Array.isArray(suppliers)))
    this.orders = orders
    this.rfqStatus  = rfqStatus
    this.selectedOffers = []
    this.isAllowscsChk = false
    this.selectedPriceSet = ''
    this.suppliers = JSON.parse(JSON.stringify(suppliers))
    let suppliersCopy = ''
    if (Array.isArray(suppliers)) {
      suppliersCopy = suppliers.slice()
    } else if (typeof suppliers === 'object') {
      suppliersCopy = Object.assign({}, suppliers)
    }

    this.parts = JSON.parse(JSON.stringify(parts))
    this.partsOrdered = JSON.parse(JSON.stringify(partsOrdered))
    //_.forEach(this.parts, (part, id) => {
    //    this.parts[id] = new Part(part);
    //});

    this.rrps = {}
    this.selectedSupplierId = 0
    this.parts.map(part => {
      part.altPartNames = []
      part.isLinked = !!part.isLinked
      if (part.type === 'part') {
        part.isOriginalPart = true
      } else if (part.type === 'manual') {
        part.isManual = true
      } else if (part.type === 'optional') {
        part.isOptional = true
      } else if (part.type === 'delivery') {
        part.isDelivery = true
      }
    })

    this.sorting = {
      type: 'qty',
      direction: 'asc'
    }
    _.forEach(this.suppliers, (supplier, id) => {
      this.suppliers[id] = new Supplier(supplier)
    })

    _.forEach(this.suppliers, (supplier, id) => {

      _.forEach(this.parts, part => {
        if (!_.isEmpty(this.suppliers[id].parts[part.id])) {
          this.suppliers[id].parts[part.id].qty = Number(part.qty)
        }
      })

      _.forEach(this.parts, (part) => {
        if (supplier.isSupplierTypeOEM) {
          let rrp = 0
          if (!_.isEmpty(supplier.rrp) && !_.isEmpty(supplier.rrp[part.id])) {
            rrp = supplier.rrp[part.id]
          }

          console.log('SUPPLIER' + supplier.id, supplier)

          if (!_.isEmpty(supplier.parts) && !_.isEmpty(supplier.parts[part.id]) && !_.isEmpty(supplier.parts[part.id].number)) {
            part.number = supplier.parts[part.id].number
          }
          this.rrps[part.id] = Number(rrp)
        }

        let suppPart = supplier.getPart(part.id)
        if (suppPart && !_.isEmpty(suppPart.altPartName)) {
          part.altPartNames.push(suppPart.altPartName)
        }

      })

      //for manual parts

      if (!_.isEmpty(this.suppliers[id].parts[0])) {
        _.forEach(this.suppliers[id].parts[0], part => {
          this.rrps[part.id] = Number(part.rrp)
          let partObj = _.find(this.parts, partSupplier => {
            return Number(partSupplier.id) === part.id
          })
          if (!_.isEmpty(partObj)) {
            partObj.qty = part.qty
            partObj.partId = part.id
            // Add delivery info
            if (partObj.type === 'delivery') {
              this.suppliers[id].parts[0][part.id].type = partObj.type
              this.suppliers[id].parts[0][part.id].name = partObj.deliveryName
              this.suppliers[id].parts[0][part.id].number = partObj.deliveryZone
            }
          }
        })
      }

    })

    this.parts.map(part => {
      part.suppliedBy = ''
      if (this.rrps && this.rrps[part.id] && this.rrps[part.id] == -1) {
        part.rrp = ''
      } else if (this.rrps && this.rrps[part.id]) {
        part.rrp = Number(this.rrps[part.id])
      } else {
        part.rrp = 0
      }

      part.qty = Number(part.qty)

      if (part.type === 'delivery') {
        part.name = part.deliveryName
        part.number = part.deliveryZone
      }
    })

    _.forEach(this.suppliers, (supplier) => {
      _.forEach(this.rrps, (rrp, id) => {
        if (!_.isEmpty(supplier.parts[id])) {
          supplier.parts[id].rrp = rrp
        }
      })
    })

    let i = 0
    _.forEach(this.parts, (part) => {
      if (part.isOriginalPart) {
        if (!part.hasOwnProperty('listOrder') || Number(part.listOrder) === 999) {
          part.listOrder = 100 + (100 * i++)
        }
      }
    })

    for (let suppId in this.suppliers) {
      for (let partId in this.suppliers[suppId].parts) {
        if (Number(partId) === 0) {
          for (let partId2 in this.suppliers[suppId].parts[partId]) {
            let part = _.find(this.parts, (prt) => {
              return Number(prt.id) === Number(partId2)
            })
            if (part) {
              part.stock = part.stock ? Object.assign(part.stock, {}) : {}
              part.stock[suppId] = this.suppliers[suppId].parts[partId][partId2].stock
            }
          }
        } else {
          let part = _.find(this.parts, (prt) => {
            return Number(prt.id) === Number(partId)
          })
          if (part) {
            part.stock = part.stock ? Object.assign(part.stock, {}) : {}
            part.stock[suppId] = this.suppliers[suppId].parts[partId].stock
          }
        }
      }
    }

    console.log('listOrder', _.cloneDeep(this.parts) )

    let s = 0
    _.forEach(this.suppliers, supplier => {
      if (_.isEmpty(supplier.parts[0])) return
      let last = 0
      let parts = []
      let temp_man = []
      let temp_not_man = []

      temp_man = _.filter(supplier.parts[0], () => true)
      temp_not_man = _.filter(supplier.parts, (e, i) => i != 0)

      //console.log('temp_man', temp_man);
      //console.log('temp_not_man', Object.assign({},temp_not_man));

      parts = _.union(temp_not_man, temp_man)
      console.log('Unordered:', _.cloneDeep(parts))
      parts = _.orderBy(parts, [(itm) => Number(itm.listOrderNumber),(itm) => Number(itm.id)])
      console.log('Ordered:', _.cloneDeep(parts))

      //console.log('ALL PARTS', parts);

      _.forEach(parts, (part) => {
        //  console.log('partNew', part);
        if (part.isOriginalPart) {
          let p = _.find(this.parts, (p) => p.id == part.originalPartId)
          if (p) {
            last = p.listOrder
            console.log('p %s is original, order %s', p.name, p.listOrder, p)
            console.log(part)
          } else {
            console.log('NOT MANUAL Part: %o, Parts: %o', part, this.parts)
          }
          //console.log('last', last);
          return
        } else if (part.isOptional) {

        } else {
          let p = _.find(this.parts, (p) => p.id == part.id)
          if (p) {
            if (!p.hasOwnProperty('listOrder') || Number(p.listOrder) === 999) {
              p.listOrder = last * 1 + s * 10 + part.listOrderNumber * 1
            }
            console.log('p %s, order %s', p.name, p.listOrder, p)
            console.log('p order', p.listOrder)
          } else {
            console.log('no part found Part: %o, Parts: %o', part, this.parts)
          }
        }

      })


      // TEMP SORTING FUNCTION OVERRIDING TO FIX ITEMS ORDER FOR ONE SUPPLIER SITUATION
      // TODO: rework original sorting function
      let sl = 0
      _.forEach(this.suppliers, () => sl++)
      if (sl === 1) {
        _.forEach(parts, (part) => {
          if (part.isOriginalPart) {
            let p = _.find(this.parts, (p) => p.id == part.originalPartId)
            if (p) {
              //if (!p.hasOwnProperty('listOrder') || Number(p.listOrder) === 999) {
                p.listOrder = s * 100 + part.listOrderNumber * 1
              //}
              console.log('p %s, order %s', part.name, part.listOrder)
            }
          } else if (part.isOptional) {

          } else {
            let p = _.find(this.parts, (p) => p.id == part.id)
           // if (!p.hasOwnProperty('listOrder') || Number(p.listOrder) === 999) {
              p.listOrder = s * 100 + part.listOrderNumber * 1
           // }
            console.log('p %s, order %s', part.name, part.listOrder)
          }

          let p = _.find(this.parts, (p) => p.id == part.id)
          if (p) {
            p.isRemovedByRepairer = part.isRemovedByRepairer
          }
        })
      }

      s++

      console.log('after set listOrder:', _.cloneDeep(parts))
      console.log('after set listOrder2:', _.cloneDeep(this.parts))

    })

    _.forEach(this.parts, (part) => {
      if (part.isOptional) {
        let p = _.find(this.parts, (p) => p.id == part.optionalLink)
        if (p) {
          if (!part.hasOwnProperty('listOrder') || Number(part.listOrder) === 999) {
            part.listOrder = p.listOrder + 1
          }
        }
      }
    })

    if (!_.isEmpty(orders)) {
      _.forEach(orders, (order) => {
        if (!_.isEmpty(order.rfqToSupplierId) && !_.isEmpty(this.suppliers[order.rfqToSupplierId])) {
          this.suppliers[order.rfqToSupplierId].orderNumber = order.number
          this.suppliers[order.rfqToSupplierId].id = order.supplierId
          this.suppliers[order.rfqToSupplierId].rfqToSupplierId = order.rfqToSupplierId
          this.suppliers[order.rfqToSupplierId].orderId = order.id
          if (_.isEmpty(this.suppliers[order.rfqToSupplierId].orderIds)) {
            this.suppliers[order.rfqToSupplierId].orderIds = [{id: order.id, number: order.number}]
          } else {
            this.suppliers[order.rfqToSupplierId].orderIds.push({id: order.id, number: order.number})
          }
          this.suppliers[order.rfqToSupplierId].orderSubtotal = order.subtotal
          this.suppliers[order.rfqToSupplierId].orderTotal = order.total
          this.suppliers[order.rfqToSupplierId].orderGst = order.gst
          this.suppliers[order.rfqToSupplierId].orderDiscount = order.discount
          this.suppliers[order.rfqToSupplierId].orderedParts = order.partsOrdered
          this.suppliers[order.rfqToSupplierId].checkedParts = order.checkedParts
        }

        let parts = (!_.isEmpty(order.checkedParts)) ? order.checkedParts : order.parts
        _.forEach(parts, (part) => {
          let partId = (part.originalPartId !== '0') ? part.originalPartId : part.supplierPartId
          if (this.suppliers[order.rfqToSupplierId]) {
            this.suppliers[order.rfqToSupplierId].selectPart(partId, false, rfqStatus === 'Ordered')
          }
        })
      })
    } else {
      // this.getBestValueSingleSupplier()
    }

    let order = {
      'OEM-GEN': 0,
      'OEM-PAR': 1,
      'OEM-ALT/AFTM': 2,
      'OEM-ALT': 3,
      'AFTM/USED': 4,
      'CERT-AFTM': 5,
      AFTM: 6,
      USED: 7,
      RECO: 8,
    }

    this.orderedSuppliers = _.sortBy(this.suppliers, [
      s => order[s.type],
      s => s.name
    ])

    this.orderedSuppliersForRequestUpdate = _.uniqBy(this.orderedSuppliers, (itm) => itm.supplierId )
    // console.log('this.orderedSuppliers',this.orderedSuppliers)
    if ((typeof suppliersCopy === 'object' && !Array.isArray(suppliersCopy))) {
      let isHasListOrderNumber = false
      let isHasListOrder = false
      _.forEach(this.parts, (prt) => {
        if (prt.hasOwnProperty('listOrderNumber')) {
          isHasListOrderNumber = true
        }
        if (prt.hasOwnProperty('listOrder')) {
          if (Number(prt.listOrder) === 999) {
            isHasListOrder = false
          } else {
            isHasListOrder = true
          }
        }
      })
      console.log('parts for ordering', _.cloneDeep(this.parts), _.cloneDeep(isHasListOrderNumber), _.cloneDeep(isHasListOrder), _.cloneDeep(suppliersCopy))
      if (isHasListOrderNumber) {
        for (let suppId in suppliersCopy) {
          _.forEach(suppliersCopy[suppId].parts, part => {
            let originalPart = _.find(this.parts, (prt) => {
              return (Number(prt.id) === Number(part.originalPartId) && part.type === 'part') || (Number(prt.id) === Number(part.id) && part.type === 'manual')
            })
            if (originalPart && part.listOrderNumber !== null) {
              originalPart.listOrderNumber = Number(part.listOrderNumber)
            }
          })
        }
        this.parts = _.orderBy(this.parts, [(itm) => Number(itm.listOrderNumber), (itm) => Number(itm.id)])
      } else if (isHasListOrder) {
        this.parts = _.orderBy(this.parts, [(itm) => Number(itm.listOrder), (itm) => Number(itm.id)])
      } else {
        this.parts = _.orderBy(this.parts, [(itm) => Number(itm.id)])
      }

    } else {
      this.parts = _.orderBy(this.parts, [(itm) => Number(itm.listOrder), (itm) => Number(itm.id)])
    }

    let result = []
    if (this.orderedSuppliers.length >= 1 && this.orderedSuppliers?.[0]?.isAdditional) {
      result = _.orderBy(this.parts, (p) => {
        if (this.orderedSuppliers[0]?.parts?.[p.id]?.additionalSuppId && p.isAdditional) {
          return this.orderedSuppliers[0]?.parts[p.id].additionalSuppId
        } else if (!this.orderedSuppliers[0]?.parts?.[p.id]?.additionalSuppId && p.isAdditional) {
          let ordSupp = _.find(this.orderedSuppliers, (o) => {
            return o.isAdditional
          })
          if (ordSupp && !_.isEmpty(ordSupp.additionalIds)) {
            return ordSupp.additionalIds[0]
          }
        }
        return this.orderedSuppliers[0].rfqToSupplierId || this.orderedSuppliers[0].id
      });
    } else if (this.orderedSuppliers.length >= 1 && this.orderedSuppliers?.[0]?.isParent) {
      result = _.orderBy(this.parts, (p) => {
        if (this.orderedSuppliers[0]?.parts?.[p.id]?.parentSuppId && p.isParentPart) {
          return this.orderedSuppliers[0]?.parts[p.id].parentSuppId
        } else if (!this.orderedSuppliers[0]?.parts?.[p.id]?.parentSuppId && p.isParentPart) {
          let ordSupp = _.find(this.orderedSuppliers, (o) => {
            return o.isParent
          })
          if (ordSupp && !_.isEmpty(ordSupp.parentIds)) {
            return ordSupp.parentIds[0]
          }
        }
        return this.orderedSuppliers[0].rfqToSupplierId || this.orderedSuppliers[0].id
      });
    } else {
      result = _.filter(this.parts, (prt) => {
        if (rfqStatus !== 'Received' && rfqStatus !== 'Submitted') {
          return !prt.isParentPart && !prt.isAdditional
        } else {
          return prt.isParentPart || prt.isAdditional
        }
      });
      _.forEach(this.parts, (prt) => {
        if (rfqStatus !== 'Received' && rfqStatus !== 'Submitted') {
          if (prt.isParentPart || prt.isAdditional) {
            result.push(prt)
          }
        } else {
          if (!prt.isParentPart && !prt.isAdditional) {
            result.push(prt)
          }
        }
      })
    }

    console.log(result, 'result')
    this.parts.splice(0, this.parts.length);
    _.forEach(result, (prt) => {
      this.parts.push(prt)
    })

    //
    // let sorted = new Map(
    //     Object.entries(this.suppliers).sort((a,b) => {
    //         return order[a.type] - order[b.type]
    //     })
    // );
    //
    // this.suppliers = {};
    //
    // for (const [key, val] of sorted) {
    //     this.suppliers["" + key] = val;
    // }
    //
    // console.log('AFTER SORTING', this.suppliers);
  }

  /**
   * This section will display calculated results from the pricing that was
   * submitted from the OEM supplier only. and not others
   *
   * Your Profit = the difference between the total of the RRP price, subtracted from the Buy price.
   * Buy Price = the total of all buy price amounts
   * Mark up  = the percentage representation of the Profit to be made
   * Sell Price = the total of the RRP
   * @returns {{buyPrice: *, markUp: number, sellPrice: number, profit: number}}
   * @constructor
   */
  get OEMPartsPurchase () {
    let buyPrice = 0,
      sellPrice = 0
    _.forEach(this.suppliers, (supplier) => {
      if (supplier.isSupplierTypeOEM) {
        buyPrice = supplier.priceTotal
      }
    })

    _.forEach(this.suppliers, (supplier) => {
      if (supplier.isSupplierTypeOEM) {
        _.forEach(supplier.parts, (part, id) => {
          if (!part.isParentPart && !part.isAdditional && this.rrps && this.rrps[id]) {
            sellPrice += this.rrps[id] * part.qty;
          }
        })
        if (!_.isEmpty(supplier.parts[0])) {
          _.forEach(supplier.parts[0], (part, id) => {
            if (!part.isParentPart && !part.isAdditional && this.rrps && this.rrps[id]) {
              sellPrice += this.rrps[id] * part.qty;
            }
          })
        }
      }
    })

    return this.calculatePartsPurchase(buyPrice, sellPrice)
  }

  /**
   * This section is similar to the OEM one ,
   * except it must dynamically display the calculation results of any selection
   * combinations from suppliers (OEM, AFTM USED,RECO)even if that selection is just the OEM for all parts pricing.
   * @returns {{buyPrice: *, markUp: number, sellPrice: number, profit: number}}
   */
  get selectedPartsPurchase () {
    let buyPrice = 0,
      sellPrice = 0

    _.forEach(this.suppliers, (supplier) => {
      buyPrice += supplier.priceSelected
    })

    _.forEach(this.suppliers, (supplier) => {
      supplier.selectedParts.forEach(id => {
        if (this.rrps && this.rrps[id]) {
          let part = this.getPart(id);
          let qty = 1;
          if (part) {
            if (part.isParentPart || part.isAdditional) {
              return
            }
            qty = part.qty;
          }
          sellPrice += this.rrps[id] * qty;
        }
      })
    })

    return this.calculatePartsPurchase(buyPrice, sellPrice)
  }

  /**
   * Lowest price
   * @returns {{supplierId: string, price: number}}
   */
  get lowestPrice () {
    return this.getLowestPrice()
  }

  /**
   * Get only selected suppliers
   * @return {Array}
   */
  get selectedSuppliers () {
    return _.filter(this.orderedSuppliers, (supplier) => {
      return supplier.priceSelected > 0
    })
  }

  /**
   * Get only selected parts
   * @returns {Array}
   */
  get selectedParts () {
    let selected = []
    _.forEach(this.suppliers, supplier => {
      selected = supplier.selectedParts.concat(selected)
    })
    return _.filter(this.parts, (part) => {
      return selected.includes(part.id)
    })
  }

  /**
   * Get selected parts
   * @returns {[]}
   */
  getSelectedPartsIds () {
    let result = []
    _.forEach(this.suppliers, supplier => {
      result = supplier.getSelectedPartsIds().concat(result)
    })
    return result
  }

  /**
   * Check is part selected for all suppliers
   * @param partId
   * @returns {boolean}
   */
  isPartSelected (partId) {
    let result = false
    _.forEach(this.suppliers, supplier => {
      if (supplier.isPartSelected(partId)) {
        result = true
      }
    })
    return result
  }

  /**
   * Calculate Purchase
   * @param buyPrice
   * @param sellPrice
   * @returns {{buyPrice: *, markUp: number, sellPrice: number, profit: number}}
   */
  calculatePartsPurchase (buyPrice, sellPrice) {
    let profit = 0,
      markUp = 0

    if (buyPrice && sellPrice) {
      profit = sellPrice - buyPrice
      markUp = (profit / (buyPrice / 100)) / 100

      if (profit < 0) {
        profit = markUp = 0
      }
    }

    return {
      profit: profit,
      buyPrice: buyPrice,
      markUp: markUp,
      sellPrice: sellPrice
    }
  }

  /**
   * Lowest price
   * @param excludedIds
   * @returns {{supplierId: string, price: number}}
   */
  getLowestPrice (excludedIds = []) {
    let price = 0
    let supplierId = ''
    _.forEach(this.suppliers, (supplier, key) => {
      if (_.indexOf(excludedIds, key) === -1) {
        if (!price) {
          price = supplier.priceTotal
          supplierId = key
        } else if (this.suppliers[key].priceTotal < price) {
          price = supplier.priceTotal
          supplierId = key
        }
      }
    })
    return { supplierId, price }
  }

  /**
   * Unselect all parts
   */
  unselectAll () {
    _.forEach(this.suppliers, (supplier) => {
      supplier.unselectAllParts()
    })
  }


  orderParts () {
    let isHasLinked = _.some(this.parts, (prt) => {
      return this.partIsLinked(prt)
    })
    if (!isHasLinked) {
      return;
    }
    if (this.OEMSupplier && this.OEMSupplier.parts) {
      _.forIn(this.OEMSupplier.parts, (prt, prtId) => {
        if (Number(prtId) === 0) {
          _.forIn(this.OEMSupplier.parts[0], (manualPrt) => {
            let part = _.find(this.parts, (p) => {
              return Number(p.id) === Number(manualPrt.id);
            })
            if (part) {
              part.listOrder = manualPrt.listOrderNumber;
            }
          })
        } else {
          let part = _.find(this.parts, (p) => {
            return Number(p.id) === Number(prtId);
          })
          if (part) {
            part.listOrder = prt.listOrderNumber;
          }
        }

      })
      this.parts = _.orderBy(this.parts, [(itm) => Number(itm.listOrder)])
    }
  }


  /**
   * Find supplier with lowest parts price
   *
   * @param inStockOnly
   */
  selectPartsByIds (ids) {
    this.parts.forEach(part => {
      let suppId = null
      let suppPrt = null
      for (let sid in this.suppliers) {
        if (this.suppliers[sid]) {
          this.suppliers[sid].selectedParts = _.filter(this.suppliers?.[sid]?.selectedParts, (supplierPrtId) => {
            return ids?.includes(this?.suppliers?.[sid]?.parts?.[supplierPrtId]?.id) || ids?.includes(this?.suppliers?.[sid]?.parts?.[0]?.[supplierPrtId]?.id)
          })
          for (let partId in this?.suppliers?.[sid]?.parts) {
            let p = Number(partId) === 0 ? this.suppliers?.[sid]?.parts?.[partId]?.[part?.id] : this.suppliers?.[sid]?.parts?.[partId];
            if (
                ids?.includes(p?.id) &&
                (
                    (Number(partId) !== 0 && Number(p?.originalPartId) === Number(part?.id)) ||
                    (Number(partId) === 0 && Number(p?.id) === Number(part?.id))
                )
            ) {
              suppPrt = p;
              suppId = sid;
            }
          }
        }
      }

      if (suppId && suppPrt) {
        this.suppliers?.[suppId]?.selectPart(part?.id)
      }
    })
  }

  setBestValueAllSupplier (inStockOnly = false) {
    this.isAllowscsChk = false;
    console.log('inStockOnly', inStockOnly)

    this.parts.forEach(part => {
      let cheapestSid = null
      let cheapestPrice = null

      for (let sid in this.suppliers) {
        // console.log("Supplier: %s, Part: %s, Stock: %s", sid, part.id, this.suppliers[sid].parts[part.id].stock);
        if (
          this.suppliers[sid].rfqToSupplierStatus == 'New'
          || this.suppliers[sid].rfqToSupplierStatus == 'Closed'
          || !this.suppliers[sid].parts[part.id]
          || this.suppliers[sid].parts[part.id].stock == 3
          || this.suppliers[sid].parts[part.id].stock == 5
          || (
            inStockOnly
            && this.suppliers[sid].parts[part.id].stock != 1
            && this.suppliers[sid].parts[part.id].stock != 2
            // commented by #640
            // && this.suppliers[sid].parts[part.id].stock != 4
            && this.suppliers[sid].parts[part.id].stock != 6
          )
        ) continue

        if (this.suppliers[sid].parts[part.id].price && (cheapestSid == null || cheapestPrice > this.suppliers[sid].parts[part.id].price)) {
          cheapestSid = sid
          cheapestPrice = this.suppliers[sid].parts[part.id].price
        }
      }
      if (cheapestSid != null) {
        this.suppliers[cheapestSid].selectPart(part.id, true)
      }
    })
    // _.forEach(this.parts, (part) => {
    //     let partWithMinPrice = {},
    //         supplierId = 0,
    //         partId = 0;
    //     _.forEach(this.suppliers, supplier => {
    //         let currentPart = supplier.getPart(part.id);
    //         if (!_.isEmpty(currentPart)) {
    //             if (partWithMinPrice.price > currentPart.price || _.isEmpty(partWithMinPrice)) {
    //                 if ((inStockOnly && currentPart.status !== 'out_of_stock') || !inStockOnly) {
    //                     partWithMinPrice = currentPart;
    //                     supplierId = supplier.id;
    //                     partId = part.id;
    //                 }
    //             }
    //         }
    //     });
    //     if(supplierId) this.suppliers[supplierId].selectPart(partId);
    // });
  }

  isExistInOrder(id, rfqToSupplierId) {
    return _.some(this.orders, (o) => {
      return Number(o.rfqToSupplierId) == Number(rfqToSupplierId) && _.some(o.partsOrdered, (p) => {
        return Number(p.partId) === Number(id)
      })
    })
  }

  getPricesByRule (rule) {
    console.log(rule, 'rule')
    if (rule.isAllowscsChk) {
      if (!(this.isAllowscsChk && this.selectedPriceSet == '')) {
        this.isAllowscsChk = !this.isAllowscsChk;
      }
    } else {
      this.isAllowscsChk = false;
      let action = rule.action;
      this.parts.forEach(part => {

        let cheapestSid = null
        let cheapestPrice = null

        for (let sid in this.suppliers) {
          //console.log('Supplier: %s, Part: %s, Stock: %s', sid, part.id, this.suppliers[sid].parts[part.id].stock)
          if (
              this.suppliers[sid].rfqToSupplierStatus == 'New'
              || this.suppliers[sid].rfqToSupplierStatus == 'Closed'
              || !this.suppliers[sid].parts[part.id]
              || this.suppliers[sid].parts[part.id].stock == 3
              || this.suppliers[sid].parts[part.id].stock == 5
              || (this.suppliers[sid].parts[part.id].stock != 1
                  && this.suppliers[sid].parts[part.id].stock != 2
                  && this.suppliers[sid].parts[part.id].stock != 4
                  && this.suppliers[sid].parts[part.id].stock != 6
              )
          ) continue

          if (((this.rfqStatus === 'Ordered' && !this.isExistInOrder(this.suppliers?.[sid]?.parts?.[part.id]?.originalPartId, this.suppliers?.[sid]?.rfqToSupplierId)) || this.rfqStatus !== 'Ordered') && this.suppliers[sid].parts[part.id].price && (cheapestSid == null || cheapestPrice > this.suppliers[sid].parts[part.id].price)) {
            cheapestSid = sid
            cheapestPrice = this.suppliers[sid].parts[part.id].price
          }
        }
        if (cheapestSid != null) {
          let ruleSupplier = null;
          _.forIn(this.suppliers, (supp, suppId) => {
            if (supp.partsType === rule.suppType) {
              ruleSupplier = supp;
            }
          })
          console.log(ruleSupplier, 'ruleSupplier')
          if (ruleSupplier) {
            let suppPrice = ruleSupplier?.getOffer(part)?.price;
            if (action === 'Less Than') {
              if (Number(suppPrice) - Number(cheapestPrice) === Number(rule.value)) {
                if (!this.isOfferCanBePicked(this.suppliers[ruleSupplier.id].getOffer(part), ruleSupplier)) {
                  return
                }

                this.suppliers[ruleSupplier.id].selectPart(part.id, false, this.rfqStatus === 'Ordered')
              } else {
                if (!this.isOfferCanBePicked(this.suppliers[cheapestSid].getOffer(part), cheapestSid)) {
                  return
                }
                this.suppliers[cheapestSid].selectPart(part.id, false, this.rfqStatus === 'Ordered')
              }
            } else if (action === 'Greater Than') {
              if (Number(cheapestPrice) - Number(suppPrice) === Number(rule.value)) {
                if (!this.isOfferCanBePicked(this.suppliers[ruleSupplier.id].getOffer(part), ruleSupplier)) {
                  return
                }
                this.suppliers[ruleSupplier.id].selectPart(part.id, false, this.rfqStatus === 'Ordered')
              } else {
                if (!this.isOfferCanBePicked(this.suppliers[cheapestSid].getOffer(part), cheapestSid)) {
                  return
                }
                this.suppliers[cheapestSid].selectPart(part.id, false, this.rfqStatus === 'Ordered')
              }
            } else if (action === 'Equal To') {
              console.log(Number(cheapestPrice) === Number(rule.value), part.id, 'equeal', cheapestSid)
              if (Number(cheapestPrice) === Number(rule.value)) {
                let supplierId = this.rfqStatus === 'Ordered' &&  ruleSupplier.rfqToSupplierId ? ruleSupplier.rfqToSupplierId : ruleSupplier.id
                if (!this.isOfferCanBePicked(this.suppliers[supplierId].getOffer(part), supplierId)) {
                  return
                }
                this.suppliers[supplierId].selectPart(part.id, false, this.rfqStatus === 'Ordered')
              } else {
                if (!this.isOfferCanBePicked(this.suppliers[cheapestSid].getOffer(part), cheapestSid)) {
                  return
                }
                this.suppliers[cheapestSid].selectPart(part.id, false, this.rfqStatus === 'Ordered')
              }
            }
          }
        }
      })
    }
  }

  /**
   * Best Value Single Supplier - is the lowest total
   * price from one single supplier only if the single supplier has priced for all parts.
   * @param excludedData
   **/

  getBestValueSingleSupplier (excludedData = []) {
    this.isAllowscsChk = false;
    let excluded = excludedData//,
    //lowestPrice = this.getLowestPrice(excluded);

    let sums = {}
    let suppLengthOfParts = 0

    let maxPartsLength = 0
    for (let supId in this.suppliers) {
      if (Object.keys(this.suppliers[supId].parts).length > maxPartsLength) {
        maxPartsLength = Object.keys(this.suppliers[supId].parts).length
      }
    }

    for (let sid in this.suppliers) {
      let suppl = this.suppliers[sid]
      if (suppl.rfqToSupplierStatus == 'New' || suppl.rfqToSupplierStatus == 'Closed') continue
      let accpt = true
      let sum = 0
      if (Object.keys(suppl.parts).length < maxPartsLength) {
        continue
      }
      for (let pid in suppl.parts) {
        if (pid != 0) {
          let part = suppl.parts[pid]
          // console.log('pid', pid, part)
          if (!part) {
            continue
          }
          if (part.isNotApplicable) {
//Условие, чтобы игнорировать дальнейшую проверку при условии notApllicable
          }
           else if (part.price > 0)
            {

            } else if ((part.stock == 3 || part.stock == 5) && Object.keys(this.suppliers).length > 1) {
            accpt = false
            break
          }
          sum += part.qty * part.price
        }
      }
      if (accpt) {
        sums[suppl.id] = sum
      }
    }
    console.log('Accepted Suppliers Sums', sums)
    let cheapestId = null
    let cheapestSum = null
    for (let sid in sums) {
      let sum = sums[sid]
      if (cheapestId == null || sum < cheapestSum) {
        cheapestId = sid
        cheapestSum = sum
      }
    }

    if (cheapestId != null) {
      this.selectedSupplierId = cheapestId
      this.suppliers[cheapestId].selectAllParts(true)
      this.suppliers[cheapestId].lowestTotalPrice = true
    }

    // if (_.size(excluded) < _.size(this.suppliers)) {
    //     _.forEach(this.suppliers, (supplier) => {
    //         if (supplier.id === lowestPrice.supplierId) {
    //             if (supplier.isAllPriced(this.parts)) {
    //                 supplier.selectAllParts();
    //                 supplier.lowestTotalPrice = true;
    //                 this.selectedSupplierId = supplier.id;
    //             } else {
    //                 excluded.push(supplier.id);
    //                 this.getBestValueSingleSupplier(excluded);
    //             }
    //         }
    //     });
    // }
  }

  /**
   * Best Value from all Suppliers -
   * is the lowest total price from picking the lowest price of each part from each supplier.
   */
  getBestValueAllSupplier () {
    this.setBestValueAllSupplier()
  }

  /**
   * Best Value and Parts In Stock - is the lowest total price from picking the lowest price
   * of each part from each supplier and only where the Part is marked in stock from the supplier.
   * Lowest price for part and not in stock is not calculated in the total.
   **/
  getBestValuePartInStock () {
    this.setBestValueAllSupplier(true)
  }

  /**
   * Select all parts for supplier
   * @param supplierId
   */
  selectAllPartsFrom (supplierId) {
    this.unselectAll()
    this.suppliers[supplierId].selectAllParts()
    this.selectedSupplierId = supplierId
  }

  unSelectAllPartsFrom (supplierId) {
    this.suppliers[supplierId].unselectAllParts()
    this.selectedSupplierId = null
  }

  /**
   * Get part by id
   * @param id
   * @returns {*}
   */
  getPart (id) {
    return this.parts.filter(part => part.id === id)[0]
  }

  /**
   * Get parts where price > 0
   * @param supplierParts
   * @returns {[]}
   */
  getPartsWithPrice (supplierParts) {
    let parts = []
    _.forEach(supplierParts, (part, key) => {
      if (part.price) {
        parts.push(key)
      }
    })
    return parts
  }

  /**
   * Get of selected supplier id
   * @returns {number}
   */
  getSelectedSupplierId () {
    let supplierId = 0
    _.forEach(this.suppliers, (supplier) => {
      supplierId = 0
      let partsWithPrice = this.getPartsWithPrice(supplier.parts)
      for (let part of partsWithPrice) {
        if (supplier.isPartSelected(part)) {
          supplierId = supplier.id
        } else {
          supplierId = 0
          break
        }
      }
      if (supplierId) return false
    })

    _.forEach(this.suppliers, (supplier) => {
      if (supplier.priceSelected && supplier.id !== supplierId) {
        supplierId = 0
      }
    })
    return supplierId
  }

  /**
   * Select offer
   * @param supplierId
   * @param partId
   * @param Part part
   * @param Offer offer
   */
  selectOffer (supplierId, partId, part, offer, isOrdered = false) {
    let supplier = this.suppliers[supplierId]
    _.forEach(this.suppliers, (item) => {

      let id = item.rfqToSupplierId ? item.rfqToSupplierId : item.id

      if (((item.id !== supplierId && !isOrdered) || (id !== supplierId && isOrdered)) && item.isPartSelected(partId)) {
        item.removeSelectedPart(partId)
      }
      if (offer.isOptional && item.isPartSelected(offer.optionalLink)) {
        console.log('removeOriginalPart', offer.optionalLink)
        item.removeSelectedPart(offer.optionalLink)
      } else if (offer.hasOptional(item)) {
        console.log('hasOptional', offer.optionalLink, offer.originalPartId)
        let opOffers = item.getAllOptionalParts(offer)
        if (opOffers) {
          _.forEach(opOffers, (o) => {
            console.log('remove2', offer.id, o.id, item.isPartSelected(o.id))
            if (offer.id != o.id) {
              item.removeSelectedPart(o.id)
            }
          })
        }
        console.log('opOffers', opOffers)
      }
      //if (item.id !== supplierId  && offer.hasOptional(item) && item.isPartSelected(offer.optionalLink)){
      //    item.removeSelectedPart(offer.optionalLink);
      //}
    })
    //if (part && part.isOptional && offer && part.groupSelectId && part.groupSelectId > 0)
    //{
    //    console.log('searchPart', part)
    //    console.log('searchOffer', offer)
    //    _.forEach(this.suppliers, (item) => {
    //        _.forEach(item.offers, (o)=>{
    //            if ((Number(o.originalPartId) === Number(part.groupSelectId) && Number(o.originalPartId) > 0) || (Number(o.optionalLink) > 0 && Number(o.optionalLink) === Number(part.groupSelectId) )){
    //                console.log('findO',o)
    //               item.removeSelectedPart(o.id)
    //            }
    //        })
    //        //if (item.isPartSelected(partId) && item.id !== supplierId) item.removeSelectedPart(partId);
    //
    //    });
    //    //supplier.selectPart(partId);
    //}

    if (supplier.isPartSelected(partId)) {
      supplier.removeSelectedPart(partId)
    } else {
      supplier.selectPart(partId, false, isOrdered)
    }
    //this.selectedOffers.push(offer);

    this.selectedSupplierId = this.getSelectedSupplierId()
  }

  /**
   * Sort parts
   * type === field name
   * @param type
   */
  sortBy (type) {
    this.sorting.type = type
    if (this.sorting.direction === 'asc') this.sorting.direction = 'desc'
    else this.sorting.direction = 'asc'

    this.parts = (this.sorting.direction === 'asc') ? _.sortBy(this.parts, this.sorting.type) :
      _.reverse(_.sortBy(this.parts, this.sorting.type))
  }

  /**
   * get OEMSupplier
   * return {Supplier}
   *
   * This OEM Supplier will determine:
   * - part alt names - getAltPartName
   * - part number
   * - linked part name
   */
  get OEMSupplier () {
    if (this.suppliers)  {
      if (!_.isEmpty(Object.values(this.suppliers).filter(supplier => supplier.isSupplierTypeOEM)?.[0])) {
        return Object.values(this.suppliers).filter(supplier => supplier.isSupplierTypeOEM)[0]
      }
    }
    return []
  }

  /**
   * getAltPartName
   * @param {String} partId
   *
   * get alternative part name from OEM Supplier
   */

  getAltPartName (partId) {
    let alternativePartName = '';
    let isMultiplySupp = _.size(this.suppliers) > 1;
    let prt = this.getPart(partId);

    if (!_.isEmpty(this.selectedSuppliers) && !(prt && (prt.isParentPart || prt.isAdditional))) {
      _.forEach(this.selectedSuppliers, (supp) => {
        let part = null;
        if (supp.parts && supp.parts[0] && !_.isEmpty(supp.parts[0][partId])) {
          part = supp.parts[0][partId];
        } else if (supp.parts && !_.isEmpty(supp.parts[partId])){
          part = supp.parts[partId];
        }
        if (!_.isEmpty(part)) {
          if (supp.selectedParts && supp.selectedParts.includes(String(partId)) && part.alternativePartName) {
            alternativePartName = part.alternativePartName;
          } else if (!(supp.selectedParts && supp.selectedParts.includes(String(partId))) && part.alternativePartName && part.stockComment === 'NOT APPLICABLE') {
            alternativePartName = part.alternativePartName;
          }
        }
      })
    } else if (!isMultiplySupp) {
      alternativePartName = (this.OEMSupplier && this.OEMSupplier.parts && this.OEMSupplier.parts[partId]) ? this.OEMSupplier.parts[partId].alternativePartName : '';
    }
    return alternativePartName
  }

  getPartLinkedName (part) {
    if (part.type === 'manual' || part.type === 'delivery') {
      if (Number(part.linkId) > 0) {
        if (this.OEMSupplier && this.OEMSupplier.parts) {
          let suppPartId = null;
          let linkId = part.linkId;
          _.forIn(this.OEMSupplier.parts, (prt, prtId) => {
            if (Number(prtId) === 0 && prt[linkId] && prt[linkId].id && Number(prt[linkId].id) === Number(linkId)) {
              suppPartId = linkId;
            } else if (Number(prtId) !== 0 && Number(prt.id) === Number(linkId)) {
              suppPartId = prtId;
            }
          })
          if (suppPartId) {
            let linkedPart = _.find(this.parts, (prt) => {
              return Number(prt.id) === Number(suppPartId);
            })
            return linkedPart.name ? linkedPart.name : '';
          }
        }
      }
    } else {
      return part.linkedName ? part.linkedName : '';
    }
  }

  /**
   * partIsLinked
   * @param Part part
   *
   * determine if a part linked to other part
   */
  partIsLinked (part) {
    if (part.isOriginalPart) {
      let pricePart = _.find(this.parts, p => p.id === part.id)
      if (!pricePart) {
        if (this.OEMSupplier.parts[part.id].linkId) {
          return true
        }
      }
      return (pricePart) ? pricePart.isLinked : false
    } else {
      // for manual parts, partId is supplier's partId
      // currently only looking for OEM supplier
      if (part.type === 'delivery' || part.type === 'manual') {
        return this.OEMSupplier && this.OEMSupplier.parts && this.OEMSupplier.parts[0] && this.OEMSupplier.parts[0][part.id] && this.OEMSupplier.parts[0][part.id].linkId
      } else if (this.OEMSupplier.parts && this.OEMSupplier.parts[part.id] && this.OEMSupplier.parts[part.id].linkId) {
        return true
      } else {
        // TODO: search other supplier also
        return false
      }
    }
  }

  partIsWithNoCost (part) {
    return _.some(this.suppliers, (supp) => {
      if (part.type === 'manual' || part.type === 'delivery') {
        return supp && supp.parts && supp.parts[0] && supp.parts[0][part.id] && supp.parts[0][part.id].linkType && supp.parts[0][part.id].linkType === 'withNoCost'
      } else {
        return supp && supp.parts && supp.parts[part.id] && supp.parts[part.id].linkType && supp.parts[part.id].linkType === 'withNoCost'
      }
    })
  }

  partIsWithCost (part) {
    return _.some(this.suppliers, (supp) => {
      if (part.type === 'manual' || part.type === 'delivery') {
        return supp && supp.parts && supp.parts[0] && supp.parts[0][part.id] && supp.parts[0][part.id].linkType && supp.parts[0][part.id].linkType === 'withCost'
      } else {
        return supp && supp.parts && supp.parts[part.id] && supp.parts[part.id].linkType && supp.parts[part.id].linkType === 'withCost'
      }
    })
  }

  /**
   * getPartNumber
   * @param {String} partId
   *
   * get part number from OEM Supplier
   */
  getStockName (partId) {
    let r = ''
    let prt = this.getPart(partId);

    if (!_.isEmpty(this.selectedSuppliers) && !(prt && prt.isParentPart)) {
      _.forEach(this.selectedSuppliers, (supp) => {
        let part = null;
        if (supp.parts && supp.parts[0] && !_.isEmpty(supp.parts[0][partId])) {
          part = supp.parts[0][partId];
        } else if (supp.parts && !_.isEmpty(supp.parts[partId])){
          part = supp.parts[partId];
        }

        if (!_.isEmpty(part, supp.selectedParts)) {
          if (!!(supp.selectedParts && supp.selectedParts.includes(String(partId)))) {
            console.log(part.stock, 'get comment name')
            if (part.stock === 0) {
              r = 'None'
            } else if (part.stock === 1) {
              r = 'In Stock'
            } else if (part.stock === 2) {
              r = 'Low Stock'
            } else if (part.stock === 3) {
              r = 'No Stock'
            } else if (part.stock === 4) {
              r = 'On Back Order'
            } else if (part.stock === 5) {
              r = 'No Longer Avail'
            } else if (part.stock === 6) {
              r = 'EX Warehouse'
            }
          }
        }
      })
    }
    return r
  }
  getIsHasExOrBackComment (partId) {
    let r = {
      ex: false,
      back: false
    };
    let prt = this.getPart(partId);

    if (!_.isEmpty(this.selectedSuppliers) && !(prt && (prt.isParentPart || prt.isAdditional))) {
      _.forEach(this.selectedSuppliers, (supp) => {
        let part = null;
        if (supp.parts && supp.parts[0] && !_.isEmpty(supp.parts[0][partId])) {
          part = supp.parts[0][partId];
        } else if (supp.parts && !_.isEmpty(supp.parts[partId])){
          part = supp.parts[partId];
        }

        if (!_.isEmpty(part, supp.selectedParts)) {
          if ((part.stock === 4 || part.stock === 6)) {
            // if (!!(supp.selectedParts && supp.selectedParts.includes(String(partId)) && part.stockComment)) {
            if (!!(supp.selectedParts && supp.selectedParts.includes(String(partId)))) {
              if (part.stock === 4) {
                r.ex = false;
                r.back = true;
              } else {
                r.ex = true;
                r.back = false;
              }
            }
          }
        }
      })
    }
    return r
  }
  getStockComment (partId) {
    let stockComment = '';
    let prt = this.getPart(partId);

    if (!_.isEmpty(this.selectedSuppliers) && !(prt && (prt.isParentPart || prt.isAdditional))) {
      _.forEach(this.selectedSuppliers, (supp) => {
        let part = null;
        if (supp.parts && supp.parts[0] && !_.isEmpty(supp.parts[0][partId])) {
          part = supp.parts[0][partId];
        } else if (supp.parts && !_.isEmpty(supp.parts[partId])){
          part = supp.parts[partId];
        }

        if (!_.isEmpty(part, supp.selectedParts)) {
          // if ((part.stock === 4 || part.stock === 6)) {
            if (!!(supp.selectedParts && supp.selectedParts.includes(String(partId)) && part.stockComment)) {
              stockComment = part.stockComment;
            } else if (!(supp.selectedParts && supp.selectedParts.includes(String(partId))) && part.stockComment && part.stockComment === 'NOT APPLICABLE') {
              stockComment = part.stockComment;
            }
          // }
        }
      })
    }
    return stockComment
  }
  getStockCommentForAll (partId) {
    let stockComment = '';
    let prt = this.getPart(partId);

    if (!_.isEmpty(this.selectedSuppliers) && !(prt && prt.isParentPart)) {
      _.forEach(this.selectedSuppliers, (supp) => {
        let part = null;
        if (supp.parts && supp.parts[0] && !_.isEmpty(supp.parts[0][partId])) {
          part = supp.parts[0][partId];
        } else if (supp.parts && !_.isEmpty(supp.parts[partId])){
          part = supp.parts[partId];
        }

        if (!_.isEmpty(part, supp.selectedParts)) {
          if (!!(supp.selectedParts && supp.selectedParts.includes(String(partId)) && part.stockComment)) {
            stockComment = part.stockComment;
          } else if (!(supp.selectedParts && supp.selectedParts.includes(String(partId))) && part.stockComment && part.stockComment === 'NOT APPLICABLE') {
            stockComment = part.stockComment;
          }
        }
      })
    }
    return stockComment
  }
  getPartNumber (partId) {
    let partNumber = '';

    let supplier = !_.isEmpty(this.OEMSupplier) ? this.OEMSupplier : this.orderedSuppliers[0]

    if (!_.isEmpty(supplier)) {
      let part = null;
      if (supplier.parts && supplier.parts[0] && !_.isEmpty(supplier.parts[0][partId])) {
        part = supplier.parts[0][partId];
      } else if (supplier.parts && !_.isEmpty(supplier.parts[partId])){
        part = supplier.parts[partId];
      }
      if (!_.isEmpty(part)) {
        partNumber = part ? part.partNumber : '';
      }
    }
    return partNumber
  }

  getPartNumberOfSelectedPart (partId) {
    let partNumber = '';
    let isMultiplySupp = _.size(this.suppliers) > 1;
    let prt = this.getPart(partId);

    if (!_.isEmpty(this.selectedSuppliers) && !(prt && (prt.isParentPart || prt.isAdditional))) {
      _.forEach(this.selectedSuppliers, (supp) => {
        let part = null;
        if (supp.parts && supp.parts[0] && !_.isEmpty(supp.parts[0][partId])) {
          part = supp.parts[0][partId];
        } else if (supp.parts && !_.isEmpty(supp.parts[partId])){
          part = supp.parts[partId];
        }
        if (!_.isEmpty(part)) {
          if (supp.selectedParts && supp.selectedParts.includes(String(partId)) && part.partNumber) {
            partNumber = part.partNumber;
          } else if (!isMultiplySupp && (supp.partsType === 'OEM-GEN' || supp.partsType === 'OEM-PAR')) {
            partNumber = part.partNumber;
          } else if (!(supp.selectedParts && supp.selectedParts.includes(String(partId))) && part.partNumber && part.stockComment === 'NOT APPLICABLE') {
            partNumber = part.partNumber;
          }
        }
      })
    } else {
      partNumber = (this.OEMSupplier && this.OEMSupplier.parts && this.OEMSupplier.parts[partId]) ? this.OEMSupplier.parts[partId].partNumber : '';
    }
    return partNumber
  }

  getRrp (partId) {
    let supp = this.OEMSupplier;
    let part = null;
    if (supp.parts && supp.parts[0] && !_.isEmpty(supp.parts[0][partId])) {
      part = supp.parts[0][partId];
    } else if (supp.parts && !_.isEmpty(supp.parts[partId])){
      part = supp.parts[partId];
    }
    if (part) {
      return part.rrp;
    }
    return 0
  }

  getPartNumberForSubmitted (partId) {
    let partNumber = '';
    if (!_.isEmpty(this.OEMSupplier) && this.OEMSupplier.rfqToSupplierStatus === 'New') {
      let prt = _.find(this.parts, (p) => {
        return Number(p.id) === Number(partId);
      })
      if (prt && !_.isEmpty(prt.numberBySupplier)) {
        partNumber = prt.numberBySupplier;
      }
    }
    return partNumber
  }

  getDeclineReasonForSubmitted () {
    let reason = '';
    let supplier = !_.isEmpty(this.OEMSupplier) ? this.OEMSupplier : null;

    if (_.isEmpty(supplier) && !_.isEmpty(this.suppliers)) {
      let ids = Object.keys(this.suppliers);
      supplier = this.suppliers[ids[0]]
    }
    if (!_.isEmpty(supplier)) {
      if (supplier?.rfqToSupplierStatus === 'Decline' && supplier?.declineReason) {
        reason = supplier.declineReason
      }
    }
    return reason
  }

  getPartNameForSubmitted (partId) {
    let partName = '';
    if (!_.isEmpty(this.OEMSupplier) && this.OEMSupplier.rfqToSupplierStatus === 'New') {
      let prt = _.find(this.parts, (p) => {
        return Number(p.id) === Number(partId);
      })
      if (prt && !_.isEmpty(prt.nameBySupplier)) {
        partName = prt.nameBySupplier;
      }
    }
    return partName
  }

  getPartRrpForSubmitted (partId) {
    let partRrp = '';
    if (!_.isEmpty(this.OEMSupplier) && this.OEMSupplier.rfqToSupplierStatus === 'New') {
      let prt = _.find(this.parts, (p) => {
        return Number(p.id) === Number(partId);
      })
      if (prt && prt.rrpBySupplier) {
        partRrp = prt.rrpBySupplier;
      }
    }
    return partRrp
  }

  getPartNumberForOrderedQuote (partId) {
    let partNumber = '';
    let isMultiplySupp = _.size(this.suppliers) > 1;

    _.forIn(this.suppliers, (supp, suppId) => {
      let part = null;
      if (supp.parts && supp.parts[0] && !_.isEmpty(supp.parts[0][partId])) {
        part = supp.parts[0][partId];
      } else if (supp.parts && !_.isEmpty(supp.parts[partId])){
        part = supp.parts[partId];
      }

      let orderedPrt = _.find(supp.orderedParts, (p) => {
        return Number(partId) === Number(p.partId);
      });

      if (!_.isEmpty(part)) {
        if (orderedPrt && part.partNumber) {
          partNumber = supp.parts[partId].partNumber
        } else if (!isMultiplySupp && (supp.partsType === 'OEM-GEN' || supp.partsType === 'OEM-PAR')) {
          partNumber = part.partNumber;
        }  else if (!orderedPrt && part.partNumber && part.stockComment === 'NOT APPLICABLE') {
          partNumber = part.partNumber
        }
      }
    })
    return partNumber
  }


  /**
   * isOfferOrdered
   * checked if current offer was ordered
   *
   * @param Offer offer
   * @return boolean
   */
  isOfferOrdered (offer) {
    if (!offer) {
      return false
    }
    let status = false
    _.forEach(this.partsOrdered, (i) => {
      if (Number(i.offerId) === Number(offer.id)) {
        status = true
        return
      }
    })
    return status
  }

  isOfferCanBePicked (offer, supplier) {
    return _.some(this.orderedSuppliers, (s) => {
      let id = s.rfqToSupplierId ? s.rfqToSupplierId : s.id
      let id2 = supplier.rfqToSupplierId ? supplier.rfqToSupplierId : supplier.id
      return Number(id) !== Number(id2) && _.some(s.offers, (ofr) => {
        return Number(ofr.originalPartId) === Number(offer.originalPartId) && this.isCancelledPartOrHasCredit(ofr, s)
      })
    })
  }

  isCancelledPartOrHasCredit(prt, supplier) {
    let id = supplier.rfqToSupplierId ? supplier.rfqToSupplierId : supplier.id
    let orderedPrt = _.find(this.partsOrdered, (p) => {
      return Number(p.rfqToSupplierId) === Number(id) && Number(p.offerId) == Number(prt.id)
    })
    return !!orderedPrt?.isCancelled || !!orderedPrt?.isHasCredit;
  }

}
