import HomePage from "../views/home/Home";
import AuthLoginPage from "../views/auth/login";
import Settings from "../views/settings/the-settings";
import RouterAuthByCode from "../views/auth/by-code";
import ForgotUserNamePage from "../views/auth/forgot";
import SupportUserNamePage from "../views/auth/forgot-userdata";
import ForgotPasswordPage from "../views/auth/forgot_pass";
import ResetPasswordPage from "../views/auth/password";
import SupportPasswordPage from "../views/auth/SupportFormPassword";
import RouterContainer from "@/views/container";
import RepairerFindBusiness from "@/views/auth/register/repairer/find-business";
import RepairerFillBusinessDetails from "@/views/auth/register/repairer/fill-businnes-details";
import RepairerConfirmBusinessDetails from "@/views/auth/register/repairer/confirm-businnes-details";
import RepairerCreateBusinessProfile from "@/views/auth/register/repairer/create-businnes-profile";
import RepairerAddWorkshoplocation from "@/views/auth/register/repairer/add-workshop-location";
import RepairerAddPersonalUserAccount from "@/views/auth/register/repairer/add-personal-user-account";
import RepairerAccountCreated from "@/views/auth/register/repairer/account-created";
import RepairerFoundProblem from "@/views/auth/register/repairer/found-problem";
import RepairerFindAccount from "@/views/auth/register/repairer/find-account";
import SupplierFindBusiness from "@/views/auth/register/supplier/find-business";
import SupplierFillBusinessDetails from "@/views/auth/register/supplier/fill-businnes-details";
import SupplierConfirmBusinessDetails from "@/views/auth/register/supplier/confirm-businnes-details";
import SupplierCreateBusinessProfile from "@/views/auth/register/supplier/create-businnes-profile";
import SupplierAddWorkshoplocation from "@/views/auth/register/supplier/add-workshop-location";
import SupplierAddPersonalUserAccount from "@/views/auth/register/supplier/add-personal-user-account";
import SupplierAccountCreated from "@/views/auth/register/supplier/account-created";
import SupplierFoundProblem from "@/views/auth/register/supplier/found-problem";
import SupplierSelectBrands from "@/views/auth/register/supplier/select-brands";
import SupplierFindAccount from "@/views/auth/register/supplier/find-account";
import RegisterPage from "@/views/auth/register/repairer/register";
import SupplierRegisterPage from "@/views/auth/register/supplier/register";
import ActivateExtensionPage from "@/views/partsbridge/ActivateExtensionPage";
import Test from "@/views/test.vue";

export default [
    {
        path: '/',
        component: HomePage
    },
    {
        path: '/test',
        component: Test
    },
    {
        path: '/login',
        component: AuthLoginPage
    },
    {
        path: '/auth-by-code/:authCode',
        name: 'AuthByCode',
        component: RouterAuthByCode
    },
    {
        path: '/auth/forgot/username',
        name: 'ForgotUserNamePage',
        component: ForgotUserNamePage
    },
    {
        path: '/auth/forgot/password',
        name: 'ForgotPasswordPage',
        component: ForgotPasswordPage
    },
    {
        path: '/auth/forgot/username/support',
        name: 'SupportUserNamePage',
        component: SupportUserNamePage
    },
    {
        path: '/auth/forgot/password/support',
        name: 'SupportPasswordPage',
        component: SupportPasswordPage
    },
    {
        path: '/reset-password/:code',
        name: 'ResetPasswordPage',
        component: ResetPasswordPage
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings
    },
    {
        path: '/partsbridge/activate',
        name: 'ActivateExtensionPage',
        component: ActivateExtensionPage
    },
    {
        path: '/repairer/register',
        component: RegisterPage
    },
    {
        path: '/supplier/register',
        component: SupplierRegisterPage
    },
    {
        path: '/supplier/register/:hash',
        component: SupplierRegisterPage
    },
    {
        path: '/register/repairer/',
        component: RouterContainer,
        children: [
            {
                path: 'find-business',
                name: 'InviteRepairerFindBusiness',
                component: RepairerFindBusiness
            },
            {
                path: 'fill-business-details',
                name: 'InviteRepairerFillDetails',
                component: RepairerFillBusinessDetails
            },
            {
                path: 'confirm-business-details',
                name: 'RepairerConfirmBusinessDetails',
                component: RepairerConfirmBusinessDetails
            },
            {
                path: 'create-business-profile',
                name: 'RepairerCreateBusinessProfile',
                component: RepairerCreateBusinessProfile
            },
            {
                path: 'add-workshop-location',
                name: 'RepairerAddWorkshoplocation',
                component: RepairerAddWorkshoplocation
            },
            {
                path: 'add-personal-user-account',
                name: 'RepairerAddPersonalUserAccount',
                component: RepairerAddPersonalUserAccount
            },
            {
                path: 'account-created',
                name: 'RepairerAccountCreated',
                component: RepairerAccountCreated
            },
            {
                path: 'found-problem',
                name: 'RepairerFoundProblem',
                component: RepairerFoundProblem
            },
            {
                path: 'find-account',
                name: 'RepairerFindAccount',
                component: RepairerFindAccount
            }
        ]
    },
    {
        path: '/register/supplier/',
        component: RouterContainer,
        children: [
            {
                path: 'find-business',
                name: 'SupplierFindBusiness',
                component: SupplierFindBusiness
            },
            {
                path: 'fill-business-details',
                name: 'SupplierFillDetails',
                component: SupplierFillBusinessDetails
            },
            {
                path: 'confirm-business-details',
                name: 'SupplierConfirmBusinessDetails',
                component: SupplierConfirmBusinessDetails
            },
            {
                path: 'create-business-profile',
                name: 'SupplierCreateBusinessProfile',
                component: SupplierCreateBusinessProfile
            },
            {
                path: 'add-workshop-location',
                name: 'SupplierAddWorkshoplocation',
                component: SupplierAddWorkshoplocation
            },
            {
                path: 'add-personal-user-account',
                name: 'SupplierAddPersonalUserAccount',
                component: SupplierAddPersonalUserAccount
            },
            {
                path: 'account-created',
                name: 'SupplierAccountCreated',
                component: SupplierAccountCreated
            },
            {
                path: 'found-problem',
                name: 'SupplierFoundProblem',
                component: SupplierFoundProblem
            },
            {
                path: 'select-brands',
                name: 'SupplierSelectBrands',
                component: SupplierSelectBrands
            },
            {
                path: 'find-account',
                name: 'SupplierFindAccount',
                component: SupplierFindAccount
            }
        ]
    },
];
