<template>
  <div class="repairer-marketplace-page">
    <div class="page-header">
      <h4>Marketplace</h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link :to="{ name: 'RepairerDashboard'}">Home</router-link>
        </li>
        <li class="breadcrumb-item active">
          View Marketplace
        </li>
      </ol>
    </div>

    <div class="d-flex mt-4">
      <div class="repairer-marketplace-body">
        <div class="repairer-marketplace-menu-block">
          <div class="tab-marketplace-category title-orders-block btn-orders-block" @click="selectCategory('Stores')"
               :class="{'active-report': isStoresCategory === 'Stores'}">
            <div class="ml-3">Stores</div>
            <div class="tab-marketplace-category-line"></div>
          </div>
          <div class="title-orders-block btn-orders-block" @click="selectCategory('Consulables')"
               :class="{'active-report': isStoresCategory === 'Consulables'}">Consulables
          </div>
          <div class="btn-orders-block" @click="selectCategory2('All Paints')"
               :class="{'active-report': isStoresCategory2 === 'All Paints'}">All Paints
          </div>
          <div class="btn-orders-block" @click="selectCategory2('Paint')"
               :class="{'active-report': isStoresCategory2 === 'Paint'}">Paint
          </div>
          <div class="btn-orders-block" @click="selectCategory2('Abrasives')"
               :class="{'active-report': isStoresCategory2 === 'Abrasives'}">Abrasives
          </div>

          <div class="title-orders-block btn-orders-block" @click="selectCategory('Services')"
               :class="{'active-report': isStoresCategory === 'Services'}">Services
          </div>
          <div class="btn-orders-block" @click="selectCategory2('Recruitment')"
               :class="{'active-report': isStoresCategory2 === 'Recruitment'}">Recruitment
          </div>
          <div class="btn-orders-block" @click="selectCategory2('Bookkeeping')"
               :class="{'active-report': isStoresCategory2 === 'Bookkeeping'}">Bookkeeping
          </div>

          <div class="title-orders-block btn-orders-block" @click="selectCategory('Products')"
               :class="{'active-report': isStoresCategory === 'Products'}">Products
          </div>
          <div class="btn-orders-block" @click="selectCategory2('Cloud Technologies')"
               :class="{'active-report': isStoresCategory2 === 'Cloud Technologies'}">Cloud Technologies
          </div>
          <div class="btn-orders-block" @click="selectCategory2('Equipment')"
               :class="{'active-report': isStoresCategory2 === 'Equipment'}">Equipment
          </div>


          <tabs ref="repairer-orders-table" cache-lifetime="0">
            <tab class-name="tab-content-table" name="My Orders" id="myOrders" ref="myOrders">
              <div class="btn-orders-block" @click="selectCategory('Orders')"
                   :class="{'active-report': isStoresCategory === 'Orders'}">Orders
              </div>
              <div class="btn-orders-block" @click="selectCategory('Refunds')"
                   :class="{'active-report': isStoresCategory === 'Refunds'}">Refunds
              </div>

            </tab>
          </tabs>
        </div>
      </div>
      <div v-if="!selectedStore && !isStoresCategory2" class="m-3 w-100">
        <div v-if="isStoresCategory === 'Stores'">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0">Featured {{ isStoresCategory }}</h4>
            <div class="tabs-nav-right-search">
              <b-form-input
                  v-model="filter"
                  type="text"
                  class="search-input"
                  placeholder="Search for Suppliers"
                  debounce="500">
              </b-form-input>
            </div>
          </div>
          <div class="featured-stores-wrapper">
            <div v-for="store in stores.slice(0, 4)" :key="store.id" class="featured-store-block"
                 @click="selectStore(store)">
              <h4 class="featured-store-title" :style="{'color': store.color, 'background-color': store.background}">
                {{ store.name }}</h4>
              <p class="featured-store-text">{{ store.description }}</p>
            </div>
          </div>
        </div>

        <div
            v-if="isStoresCategory === 'Consulables' || isStoresCategory === 'Services' || isStoresCategory === 'Products'">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0">{{ isStoresCategory }}</h4>
            <div class="tabs-nav-right-search">
              <b-form-input
                  v-model="filter"
                  type="text"
                  class="search-input"
                  placeholder="Search for Suppliers"
                  debounce="500">
              </b-form-input>
            </div>
          </div>
          <div class="featured-stores-wrapper">
            <div v-for="store in filteredStores" :key="store.id" class="featured-store-block">
              <div @click="selectStore(store)" class="h-100">
                <h4 class="featured-store-title" :style="{'color': store.color, 'background-color': store.background}">
                  {{ store.name }}</h4>
                <p class="featured-store-text">{{ store.description }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="see-more-btn d-flex justify-content-end m-4">
          See More >
        </div>

        <div class="d-flex">
          <div class="information-block">
            <h4>PARTSSEARCH Marketplace</h4>
            <br>
            <p>Buy products and services from your suppliers, online and all-in-one place.</p>
          </div>
          <div class="information-block">
            <h4>Not SEEING your supplier in here</h4>
            <br>
            <p>Earn <b>$250</b> cash when you invite your supplier & they sign up. <br><br>Plus, receive additional
              discounts on purchases for 6 months.</p>
          </div>
        </div>

        <div class="see-more-btn d-flex justify-content-end m-4">
          See More >
        </div>

        <div>
          <h4 v-if="isStoresCategory === 'Stores'">Stores servicing your region</h4>
          <h4 v-else>Suppliers in your Region</h4>
          <div class="featured-stores-wrapper">
            <div v-for="store in stores.slice(0, 4)" :key="store.id" class="featured-store-block"
                 @click="selectStore(store)">
              <h4 class="featured-store-title default-store-color"></h4>
              <p class="featured-store-text"></p>
            </div>
          </div>
        </div>
      </div>

      <div v-if="selectedStore || isStoresCategory2" class="m-3 w-100">
        <div v-if="isStoresCategory2 === 'All Paints'">
          <h4>{{ isStoresCategory }}</h4>
          <h1 class="selected-store-title">{{ isStoresCategory2 }}</h1>
          <p class="selected-store-text">All Paints is partnered with Protection Brands who is Australia's leading
            vehicle care provider, offering best in class paint & protection products as well as vehicle tinting and
            sanitisation for the major Aviation companies, as well as the Automotive and Marine markets around the
            globe.</p>
        </div>
        <div v-else>
          <h4>{{ isStoresCategory }}</h4>
          <h1 v-if="!isStoresCategory2"
              :style="{'color': selectedStore.color, 'background-color': selectedStore.background}"
              class="selected-store-title">{{ selectedStore.name }}</h1>
          <h1 v-else :style="{'color': selectedStore.color, 'background-color': selectedStore.background}"
              class="selected-store-title">{{ isStoresCategory2 }}</h1>
        </div>

        <div v-if="isStoresCategory2 !== 'All Paints'" class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <div class="text-nowrap mr-3">Category</div>
            <div>
              {{ isStoresCategory }}
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="text-nowrap mr-3">Sort By</div>
            <multiselect
                :value="sortByOptionValue"
                :options="sortByOption"
                :showLabels="false"
                :max-height="203"
                :close-on-select="true"
                placeholder="Sort By"
            />
          </div>
          <div class="tabs-nav-right-search">
            <b-form-input
                v-model="filter"
                type="text"
                class="search-input"
                placeholder="Search for Product"
                debounce="500">
            </b-form-input>
          </div>
          <div>
            <button
                ref="viewCartMarketplace"
                class="btn btn-primary pull-right button-next"
                type="button"
            >
              View CART
            </button>
          </div>
        </div>
        <div v-if="isStoresCategory2 !== 'All Paints'" class="table-wrapper mt-3">
          <b-table
              responsive
              hover
              :items="items"
              :fields="storeTableFields"
          >
            <template v-slot:cell(chkbx)="data">
              <div class="">
                <label class="form-check-inline checkbox">
                  <input class="form-check-input"
                         value="EX Warehouse"
                         type="checkbox"
                  >
                  <span class="icon "><i class='bx bx-check'></i></span>
                </label>
              </div>
            </template>
            <template v-slot:cell(image)="data">
              <div class="image-block">
                <img :src="require(`@/assets/img/${data.item.image}`)" :alt="items.number">
              </div>
            </template>
            <template v-slot:cell(description)="data">
              <div class="description-name">
                {{ data.value }}
              </div>
              <div class="description-text">
                {{ data.item.descriptionText }}
              </div>
              <div class="description-number">
                SKU: {{ data.item.number }}
              </div>
            </template>
            <template v-slot:cell(price)="data">
              <div class="">
                {{ data.value  | formatMoney }}
              </div>
            </template>
            <template v-slot:cell(qty)="data">
              <number-formatter
                  type="text"
                  v-model="data.value"
                  style="height: 41px;"
                  class="form-control numeric"
                  format="0,0"></number-formatter>
            </template>
            <template v-slot:cell(action)="data">
              <div class="">
                <button
                    class="btn btn-outline-primary pull-right button-cancel text-nowrap"
                    type="button"
                >
                  Add Cart
                </button>
              </div>
            </template>
          </b-table>
        </div>
        <iframe v-else src="/marketplace-test.html" frameborder="0" class="iframe-wrapper"></iframe>

      </div>
    </div>


  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import numberFormatter from '../../../../src/components/utility/number-formatter'

export default {
  name: 'marketplace',
  data () {
    return {
      selectedStore: null,
      isStoresCategory: 'Stores',
      isStoresCategory2: null,
      filter: '',
      stores: [
        {
          id: 1,
          name: 'Store 1',
          description: 'Paint Supplies',
          category: 'Consulables',
          category2: 'Paint',
          color: '#a4800d',
          background: '#01263e'
        },
        {
          id: 2,
          name: 'Store 2',
          description: 'Cloud Technologies',
          category: 'Products',
          category2: 'Cloud Technologies',
          color: '#0d0d0d',
          background: '#7ab1bb'
        },
        {
          id: 3,
          name: 'Store 3',
          description: 'Bookkeeping',
          category: 'Consulables',
          category2: 'Bookkeeping',
          color: '#fff',
          background: '#0d0d0d'
        },
        {
          id: 4,
          name: 'Store 4',
          description: 'Paint Supplies',
          category: 'Consulables',
          category2: 'Paint',
          color: '#0d0d0d',
          background: '#d9d9d9'
        },
        {
          id: 5,
          name: 'Store 5',
          description: 'Cloud Technologies',
          category: 'Products',
          category2: 'Cloud Technologies',
          color: '#fff',
          background: '#da2e2e'
        },
        {
          id: 6,
          name: 'Store 6',
          description: 'Equipment',
          category: 'Products',
          category2: 'Equipment',
          color: '#fff',
          background: '#0d0d0d'
        },
        {
          id: 7,
          name: 'Store 7',
          description: 'Bookkeeping',
          category: 'Consulables',
          category2: 'Bookkeeping',
          color: '#0d0d0d',
          background: '#d9d9d9'
        },
        {
          id: 8,
          name: 'Store 8',
          description: 'Recruitment',
          category: 'Services',
          category2: 'Recruitment',
          color: '#a4800d',
          background: '#01263e'
        },
        {
          id: 9,
          name: 'Store 9',
          description: 'Abrasives',
          category: 'Consulables',
          category2: 'Abrasives',
          color: '#fff',
          background: '#0d0d0d'
        },
        {
          id: 32,
          name: 'Sape',
          description: 'Paint Supplies',
          category: 'Consulables',
          category2: 'Paint',
          color: '#fff',
          background: '#da2e2e',
          fullDescription: `Sydney Automotive Paint & Equipment is partnered with Protection Brands who is Australia's leading vehicle care provider, offering best in class paint & protection products as well as vehicle tinting and sanitisation for the major Aviation companies, as well as the Automotive and Marine markets around the globe.`
        },
        {
          id: 11,
          name: 'Store 11',
          description: 'Cloud Technologies',
          category: 'Products',
          category2: 'Cloud Technologies',
          color: '#0d0d0d',
          background: '#7ab1bb'
        },
        {
          id: 10,
          name: 'Store 12',
          description: 'Bookkeeping',
          category: 'Services',
          category2: 'Bookkeeping',
          color: '#fff',
          background: '#0d0d0d'
        },
        {
          id: 13,
          name: 'Store 13',
          description: 'Recruitment',
          category: 'Services',
          category2: 'Recruitment',
          color: '#0d0d0d',
          background: '#d9d9d9'
        },
        {
          id: 14,
          name: 'Store 14',
          description: 'Abrasives',
          category: 'Consulables',
          category2: 'Abrasives',
          color: '#fff',
          background: '#da2e2e'
        },
        {
          id: 15,
          name: 'Store 15',
          description: 'Paint Supplies',
          category: 'Consulables',
          category2: 'Paint',
          color: '#fff',
          background: '#0d0d0d'
        },
        {
          id: 16,
          name: 'Store 16',
          description: 'Bookkeeping',
          category: 'Services',
          category2: 'Bookkeeping',
          color: '#0d0d0d',
          background: '#d9d9d9'
        },
        {
          id: 17,
          name: 'Store 17',
          description: 'Paint Supplies',
          category: 'Consulables',
          category2: 'Paint',
          color: '#a4800d',
          background: '#01263e'
        },
        {
          id: 18,
          name: 'Store 18',
          description: 'Recruitment',
          category: 'Services',
          category2: 'Recruitment',
          color: '#fff',
          background: '#0d0d0d'
        },
        {
          id: 19,
          name: 'Store 19',
          description: 'Paint Supplies',
          category: 'Consulables',
          category2: 'Paint',
          color: '#a4800d',
          background: '#01263e'
        },
        {
          id: 20,
          name: 'Store 20',
          description: 'Bookkeeping',
          category: 'Services',
          category2: 'Bookkeeping',
          color: '#0d0d0d',
          background: '#7ab1bb'
        },
        {
          id: 21,
          name: 'Store 21',
          description: 'Bookkeeping',
          category: 'Services',
          category2: 'Bookkeeping',
          color: '#fff',
          background: '#0d0d0d'
        },
        {
          id: 22,
          name: 'Store 22',
          description: 'Equipment',
          category: 'Products',
          category2: 'Equipment',
          color: '#0d0d0d',
          background: '#d9d9d9'
        },
        {
          id: 23,
          name: 'Store 23',
          description: 'Cloud Technologies',
          category: 'Products',
          category2: 'Cloud Technologies',
          color: '#fff',
          background: '#da2e2e'
        },
        {
          id: 24,
          name: 'Store 24',
          description: 'Paint Supplies',
          category: 'Consulables',
          category2: 'Paint',
          color: '#fff',
          background: '#0d0d0d'
        },
        {
          id: 25,
          name: 'Store 25',
          description: 'Bookkeeping',
          category: 'Services',
          category2: 'Bookkeeping',
          color: '#0d0d0d',
          background: '#d9d9d9'
        },
        {
          id: 26,
          name: 'Store 26',
          description: 'Abrasives',
          category: 'Consulables',
          category2: 'Abrasives',
          color: '#a4800d',
          background: '#01263e'
        },
        {
          id: 27,
          name: 'Store 27',
          description: 'Cloud Technologies',
          category: 'Products',
          category2: 'Cloud Technologies',
          color: '#fff',
          background: '#0d0d0d'
        },
        {
          id: 28,
          name: 'Store 28',
          description: 'Paint Supplies',
          category: 'Consulables',
          category2: 'Paint',
          color: '#a4800d',
          background: '#01263e'
        },
        {
          id: 29,
          name: 'Store 29',
          description: 'Equipment',
          category: 'Products',
          category2: 'Equipment',
          color: '#0d0d0d',
          background: '#7ab1bb'
        },
        {
          id: 30,
          name: 'Store 30',
          description: 'Bookkeeping',
          category: 'Services',
          category2: 'Bookkeeping',
          color: '#fff',
          background: '#0d0d0d'
        },
        {
          id: 31,
          name: 'Store 31',
          description: 'Paint Supplies',
          category: 'Consulables',
          category2: 'Paint',
          color: '#0d0d0d',
          background: '#d9d9d9'
        },
        {
          id: 32,
          name: 'Store 32',
          description: 'Recruitment',
          category: 'Services',
          category2: 'Recruitment',
          color: '#fff',
          background: '#da2e2e'
        },
        {
          id: 33,
          name: 'Store 33',
          description: 'Abrasives',
          category: 'Consulables',
          category2: 'Abrasives',
          color: '#fff',
          background: '#0d0d0d'
        },
        {
          id: 34,
          name: 'Store 34',
          description: 'Bookkeeping',
          category: 'Services',
          category2: 'Bookkeeping',
          color: '#0d0d0d',
          background: '#d9d9d9'
        },
        {
          id: 35,
          name: 'Store 35',
          description: 'Equipment',
          category: 'Products',
          category2: 'Equipment',
          color: '#a4800d',
          background: '#01263e'
        },
        {
          id: 36,
          name: 'Store 36',
          description: 'Cloud Technologies',
          category: 'Products',
          category2: 'Cloud Technologies',
          color: '#fff',
          background: '#0d0d0d'
        },
      ],
      categoryOption: ['Complete', 'Partial'],
      categoryOptionValue: '',
      sortByOption: ['Price Low to Hight', 'Price Hight to Low'],
      sortByOptionValue: 'Price Low to Hight',
      storeTableFields: [
        { label: 'Chkbx', key: 'chkbx', sortable: false, tdClass: '', thClass: 'text-left align-center' },
        { label: 'Image', key: 'image', sortable: false, tdClass: '', thClass: 'text-center align-center' },
        { label: 'Description', key: 'description', sortable: false, tdClass: '', thClass: 'text-left align-center' },
        {
          label: 'Stock',
          key: 'stock',
          sortable: false,
          tdClass: ' text-nowrap font-weight-bold text-left align-center',
          thClass: 'text-left align-center'
        },
        {
          label: 'Price',
          key: 'price',
          sortable: false,
          tdClass: ' font-weight-bold',
          thClass: 'text-left align-center'
        },
        { label: 'Qty', key: 'qty', sortable: false, tdClass: 'qty-block', thClass: 'text-left align-center' },
        {
          label: 'Action',
          key: 'action',
          sortable: false,
          tdClass: ' text-center align-center',
          thClass: 'text-center align-center'
        },
      ],
      items: [
        {
          id: 1,
          name: 'SEM COLOR HORIZON MIXING CLEAR 946mL',
          description: 'SEM COLOR HORIZON MIXING CLEAR 946mL',
          descriptionText: 'Base Colors are fast covering clean colors that provides the maximum color effect for custom paint jobs.',
          number: 'S2064',
          image: '4013074499.jpg',
          stock: 'In Stock',
          price: '95.26',
          qty: 1
        },
        {
          id: 2,
          name: 'Larsen',
          description: 'SEM COLOR HORIZON MIXING CLEAR 3.78L',
          descriptionText: 'Custom Finish Mix Clear is a single component colorless coating used as a carrier for custom finish concentrates and effect pigments.',
          number: 'S2060',
          image: '4013113865.jpg',
          stock: 'In Stock',
          price: '331.34',
          qty: 1
        },
        {
          id: 3,
          name: 'Geneva',
          description: 'SEM TITANIUM BASE 946mL',
          descriptionText: 'Base Colors are fast covering clean colors that provides the maximum color effect for custom paint jobs.',
          number: 'S2204',
          image: '4013145254.jpg',
          stock: 'In Stock',
          price: '156.24',
          qty: 1
        },
      ]
    }
  },
  methods: {
    selectCategory (option) {
      this.isStoresCategory = option
      this.selectedStore = ''
      this.isStoresCategory2 = null
    },
    selectCategory2 (option) {
      console.log('Selected Category2: ', option)
      this.isStoresCategory2 = option
      this.selectedStore = ''
      this.isStoresCategory = null
      if (this.isStoresCategory2 === 'All Paints' || this.isStoresCategory2 === 'Paint' || this.isStoresCategory2 === 'Abrasives') {
        this.isStoresCategory = 'Consulables'
      } else if (this.isStoresCategory2 === 'Recruitment' || this.isStoresCategory2 === 'Bookkeeping') {
        this.isStoresCategory = 'Services'
      } else if (this.isStoresCategory2 === 'Cloud Technologies' || this.isStoresCategory2 === 'Equipment') {
        this.isStoresCategory = 'Products'
      }
    },
    selectStore (store) {
      console.log('Selected Store:', store)
      this.selectedStore = store
      if (this.selectedStore.category === 'All Paints' || this.selectedStore.category === 'Paint' || this.selectedStore.category === 'Abrasives') {
        this.isStoresCategory = 'Consulables'
        this.selectedStore = 'All Paints'
      } else if (this.selectedStore.category === 'Recruitment' || this.selectedStore.category === 'Bookkeeping') {
        this.isStoresCategory = 'Services'
        this.selectedStore = 'Services'
      } else if (this.selectedStore.category === 'Cloud Technologies' || this.selectedStore.category === 'Equipment') {
        this.isStoresCategory = 'Products'
        this.selectedStore.category = 'Products'
      }
    }
  },
  computed: {
    filteredStores () {
      let filtered = []
      if (this.isStoresCategory === 'Stores') {
        filtered = this.stores
      } else if (this.isStoresCategory === 'Consulables') {
        filtered = this.stores.filter(store => store.category2 === 'Paint' || store.category2 === 'Abrasives' || store.category2 === 'All Paints')
      } else if (this.isStoresCategory === 'Services') {
        filtered = this.stores.filter(store => store.category2 === 'Recruitment' || store.category2 === 'Bookkeeping')
      } else if (this.isStoresCategory === 'Products') {
        filtered = this.stores.filter(store => store.category2 === 'Cloud Technologies' || store.category2 === 'Equipment')
      } else {
        filtered = this.stores.filter(store => store.category2 === this.isStoresCategory)
      }
      return filtered.slice(0, 4)
    },
  },
  components: {
    Multiselect,
    numberFormatter,
  },
}
</script>

<style scoped>
.breadcrumb-item {
  cursor: pointer;
}

.active {
  cursor: default;
}

.repairer-marketplace-menu-block {
  width: 170px;
  border: 1px solid rgba(27, 30, 56, 0.2);
  border-radius: 3px;
  padding: 15px;
}

.featured-stores-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.featured-store-block {
  color: #1c1f39;
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-radius: 5px;
  width: 100%;
  margin-right: 10px;
  height: 250px;
  cursor: pointer;
}

.featured-store-block:last-child {
  margin-right: 0;
}

.featured-store-title {
  padding: 15px;
  height: 50%;
  text-transform: uppercase;
}

.featured-store-text {
  margin: 15px;
}

.tab-marketplace-category {
  color: #79B1BB;
  display: flex;
  flex-direction: column;
}

.tab-marketplace-category-line {
  margin-top: 10px;
  height: 5px;
  width: 50%;
  border-radius: 5px 5px 0 0;
  background-color: #79B1BB;
}

.selected-store-title {
  background-color: #da2e2e;
  color: #fff;
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-radius: 5px;
  width: 100%;
  height: 250px;
  padding: 50px;
  font-size: 70px;
  text-transform: uppercase;
}

.selected-store-text {
  border: 1px solid rgba(27, 30, 56, 0.25);
  background-color: #f2f2f2;
  border-radius: 5px;
  width: 100%;
  height: 100px;
  padding: 15px;
  margin-top: 15px;
}

input.form-control.search-input {
  padding-left: 10px !important;
  padding-right: 35px;
  height: 32px;
  background: url(~@/assets/img/search-icon.svg) no-repeat center right;
}

.information-block {
  color: #1c1f39;
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-radius: 5px;
  width: 100%;
  margin-right: 10px;
  cursor: pointer;
  padding: 15px;
  background-color: #f2f2f2;
}

.information-block:last-child {
  margin-right: 0;
}

.see-more-btn {
  font-size: 1rem;
  font-weight: bold;
  color: #79B1BB;
  cursor: pointer;
}

.default-store-color {
  background-color: #f2f2f2;
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e3e4e7;
  padding: 15px;
}

.table-header div p {
  margin: 0;
}

.iframe-wrapper {
  width: 100%;
  /*height: calc(100vh - 700px);*/
  height: calc(100vh - 150px);
}

.description-name {
  color: #da2e2e;
}

.description-text {
  margin-top: 10px;
}

.description-number {
  margin-top: 10px;
}

.qty-block {
  max-width: 80px;
}

.image-block,
.image-block img {
  max-width: 120px;
}
</style>

<style>
.repairer-marketplace-page .nav-tabs-2 .nav-link.active,
.repairer-marketplace-page .nav-tabs-2 .nav-link.active:focus,
.repairer-marketplace-page .nav-tabs-2 .nav-link.active:hover {
  color: #79B1BB !important;
}

.repairer-marketplace-page .nav-tabs.nav-tabs-2 {
  padding-left: 0;
}

.repairer-marketplace-page .tab-content-table {
  border-top: none;
}

.repairer-marketplace-page .nav-tabs-2 .nav-link.active {
  color: #79B1BB;
}

.repairer-marketplace-page .active-report {
  color: #79B1BB;
}

.repairer-marketplace-page .btn-orders-block {
  margin: 15px 0 15px 15px;
  cursor: pointer;
}

.repairer-marketplace-page .btn-orders-block:hover {
  color: #79B1BB;
}

.repairer-marketplace-page .title-orders-block {
  font-weight: bold;
  margin: 15px 0 15px 0;
}
</style>

<style>
.repairer-marketplace-page .table-wrapper .qty-block {
  max-width: 60px;
}
</style>
